// Copyright © 2024 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { graphql } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"
import blogContent from "../../../page-content/content-blog.json"

export const catchAllCategoryName = "Other"

export const fragmentsQuery = graphql`
  fragment BlogpostSeo on Mdx {
    frontmatter {
      seo {
        title
        description
        keywords
      }
    }
  }

  fragment BlogpostMetadata on Mdx {
    frontmatter {
      title
      description

      author
      authorProfileImage {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1
            width: 48
            transformOptions: { cropFocus: CENTER, fit: COVER }
          )
        }
      }

      teaser
      category
      path
      publishedAtHumanReadable: publishedAt(formatString: "MMMM DD, YYYY")
      publishedAtMachineReadable: publishedAt(formatString: "YYYY-MM-DD")
      featuredimage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }

  fragment BlogpostContent on Mdx {
    body
  }

  fragment BlogpostFull on Mdx {
    ...BlogpostSeo
    ...BlogpostMetadata
    ...BlogpostContent
  }
`

export type BlogpostSeo = {
  title?: string
  description?: string
  keywords?: string
}

export const getBlogpostSeo = (
  blogpost: Queries.BlogpostSeoFragment,
): BlogpostSeo => {
  const seo = blogpost.frontmatter?.seo
  return {
    title: seo?.title ?? undefined,
    description: seo?.description ?? undefined,
    keywords: seo?.keywords ?? undefined,
  }
}

export type BlogAuthor = {
  name: string
  position?: string
  imgSrc?: string | ImageDataLike
  link?: string
}

const getAuthorsByAuthorNames = (
  name?: string | null,
  associatedProfilePicture?: ImageDataLike | null,
): BlogAuthor[] => {
  const authorNames = (name ?? "")
    .split(",")
    .map((name) => name.trim())
    .filter(Boolean)

  return authorNames.map((name) => {
    const author = blogContent.authors.find((author) => author.name === name)

    return {
      name: author?.fullname ?? name,
      imgSrc: associatedProfilePicture ?? undefined,
      position: author?.position,
      link: author?.url,
    }
  })
}

export type BlogpostMetadata = {
  title: string
  authors: BlogAuthor[]
  teaser?: string
  category: string
  linkToCategory: string
  url: string
  /**
   * Format: MMMM DD, YYYY
   * @example "May 09, 2023"
   */
  publishedAtMachineReadable: string
  /**
   * Format: YYYY-MM-DD
   * @example "2023-05-09"
   */
  publishedAtHumanReadable: string
  featuredImage?: ImageDataLike
  description?: string
}

export const getBlogpostMetadata = (
  blogpost: Queries.BlogpostMetadataFragment,
): BlogpostMetadata => {
  const frontmatter = blogpost.frontmatter

  if (!frontmatter) {
    throw new Error("No frontmatter!")
  }

  // Not all MDX files are blog posts (e.g. customer reviews), so some are missing
  // these properties. These verification steps ensure that all (published)
  // blogposts conform to the expected type.
  if (frontmatter.teaser === null) {
    throw new Error(`Blogpost ${frontmatter.title} has no teaser!`)
  }

  if (frontmatter.path === null) {
    throw new Error(`Blogpost ${frontmatter.title} has no path!`)
  }

  if (
    frontmatter.publishedAtHumanReadable === null ||
    frontmatter.publishedAtMachineReadable === null
  ) {
    throw new Error(`Blogpost ${frontmatter.title} has no publishedAt!`)
  }

  const category = frontmatter.category ?? catchAllCategoryName

  const authors = getAuthorsByAuthorNames(
    frontmatter.author,
    frontmatter.authorProfileImage?.childImageSharp,
  )

  return {
    title: frontmatter.title,
    description: frontmatter.description ?? undefined,
    authors: authors,
    teaser: frontmatter.teaser,
    category,
    linkToCategory: `/blog/category/${category.toLowerCase()}`,
    url: frontmatter.path,
    publishedAtHumanReadable: frontmatter.publishedAtHumanReadable,
    publishedAtMachineReadable: frontmatter.publishedAtMachineReadable,
    featuredImage: (frontmatter.featuredimage as ImageDataLike) ?? undefined,
  }
}

export type BlogContent = {
  body: string
}

export const getBlogContent = (
  blogpost: Queries.BlogpostContentFragment,
): BlogContent => ({
  body: blogpost.body,
})

export type Blogpost = {
  seo: BlogpostSeo
  metadata: BlogpostMetadata
  content: BlogContent
}

export const getBlogpost = (
  queryResult: Queries.BlogpostFullFragment,
): Blogpost => ({
  seo: getBlogpostSeo(queryResult),
  metadata: getBlogpostMetadata(queryResult),
  content: getBlogContent(queryResult),
})
