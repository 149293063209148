import classNames from "classnames"
import {
  ArrowLeft as IconArrowLeft,
  ArrowRight as IconArrowRight,
} from "phosphor-react"
import { z } from "zod"
import { Analytics } from "../../../../components/freestanding/shared-types"
import { useAnalytics } from "../../../../hook/useAnalytics"
import Link from "../../../resolvers/link"

const ArrowRight = <IconArrowRight weight="bold" size={16} className="ml-2" />
const ArrowLeft = <IconArrowLeft weight="bold" size={16} className="mr-2" />

export const buttonSizeSchema = z.enum(["xs", "sm", "base", "lg"])
export const buttonTypeSchema = z.enum([
  "primary",
  "secondary",
  "primary-accent",
  "inverted",
])
const buttonStylePropsSchema = z.object({
  variant: buttonTypeSchema.nullish(),
  className: z.string().nullish(),
  fullWidth: z.boolean().nullish(),
  nonResponsiveSize: buttonSizeSchema.nullish(),
})

export type ButtonStyleProps = z.infer<typeof buttonStylePropsSchema>

export const useButtonStyleClasses = ({
  className,
  nonResponsiveSize,
  fullWidth,
  variant,
}: ButtonStyleProps = {}): string => {
  let classes = [
    "flex",
    "items-center",
    "justify-center",
    "border",
    "border-transparent",
    "font-medium",
    "focus:outline-none",
    "focus:ring",
    "focus:ring-cyan-500",
    className,
  ]

  if (nonResponsiveSize) {
    switch (nonResponsiveSize) {
      case "xs":
        classes.push("px-3", "py-2", "text-xs")
        break
      case "sm":
        classes.push("px-3", "py-2", "text-sm")
        break
      case "base":
        classes.push("px-5", "py-2.5", "text-sm")
        break
      case "lg":
        classes.push("px-5", "py-3", "text-base")
        break
      default:
        break
    }
  } else {
    classes.push("px-5", "py-2.5", "text-sm", "xl:py-3", "xl:text-base")
  }

  switch (variant) {
    case "primary":
      classes.push(
        "text-cyan-900",
        "hover:text-cyan-50",
        "dark:hover:text-cyan-900",
        "bg-cyan-200",
        "hover:bg-gray-900",
        "dark:hover:bg-cyan-50",
        "focus:ring-cyan-300",
      )
      break
    case "secondary":
      classes.push(
        "text-gray-600",
        "dark:text-gray-300",
        "hover:text-cyan-50",
        "dark:hover:text-cyan-900",
        "hover:bg-gray-900",
        "dark:hover:bg-cyan-50",
        "dark:text-gray-300",
        "ring-1",
        "ring-solid",
        "ring-gray-900",
        "dark:ring-gray-50",
        "focus:ring-cyan-300",
      )
      break
    case "primary-accent":
      classes.push(
        "text-cyan-900",
        "hover:text-cyan-50",
        "bg-cyan-50",
        "hover:bg-gray-900",
        "focus:ring-cyan-300",
      )
      break
    case "inverted":
      classes.push(
        "text-cyan-50",
        "dark:text-cyan-900",
        "hover:text-cyan-900",
        "bg-indigo-900",
        "dark:bg-cyan-50",
        "hover:bg-cyan-200",
        "focus:ring-cyan-300",
      )
      break
    default:
      break
  }

  if (fullWidth) {
    classes.push("w-full")
  } else {
    classes.push("w-fit")
  }

  // TODO: add disabled style

  return classNames(...classes)
}

export const buttonPropsSchema = buttonStylePropsSchema.and(
  z.object({
    title: z.string(),
    to: z.string(),
    disabled: z.boolean().optional(),
    iconRight: z.literal("arrow-right").nullish(),
    iconLeft: z.literal("arrow-left").nullish(),
    analytics: z
      .custom<Analytics>()
      .nullish()
      .transform((x) => x ?? undefined),
  }),
)

const Button = ({
  title,
  to,
  variant = "primary",
  disabled = false,
  className = "",
  iconRight,
  iconLeft,
  analytics,
  fullWidth,
  nonResponsiveSize,
  ...remainingProps
}: z.infer<typeof buttonPropsSchema>) => {
  const buttonClass = useButtonStyleClasses({
    className,
    variant,
    fullWidth,
    nonResponsiveSize,
  })

  let iconRightElement = null

  if (iconRight) {
    switch (iconRight) {
      case "arrow-right":
        iconRightElement = ArrowRight
        break

      default:
        break
    }
  }

  let iconLeftElement = null

  if (iconLeft) {
    switch (iconLeft) {
      case "arrow-left":
        iconLeftElement = ArrowLeft
        break

      default:
        break
    }
  }

  const datalayer = useAnalytics(analytics)

  const onClickWithAnalytics = () => {
    datalayer()
  }

  return (
    <Link
      to={to}
      className={classNames(buttonClass)}
      onClick={onClickWithAnalytics}
      {...remainingProps}
    >
      {iconLeftElement}
      {title}
      {iconRightElement}
    </Link>
  )
}

export default Button
