import classNames from "classnames"
import Prism from "prismjs"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-css"
import "prismjs/components/prism-dart"
import "prismjs/components/prism-diff"
import "prismjs/components/prism-go"
import "prismjs/components/prism-json"
import "prismjs/components/prism-json5"
import "prismjs/components/prism-jsonp"
import "prismjs/components/prism-jsx"
import "prismjs/components/prism-makefile"
import "prismjs/components/prism-markdown"
import "prismjs/components/prism-python"
import "prismjs/components/prism-shell-session"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-tsx"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-yaml"
import "prismjs/themes/prism.css"
import { PropsWithChildren, useEffect } from "react"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import Section from "../../freestanding/section"
import "./blog-body.css"

export type BlogBodyProps = {
  className?: string
}

export const BlogBody = ({
  className,
  children,
}: PropsWithChildren<BlogBodyProps>) => {
  useEffect(() => {
    requestAnimationFrame(() => Prism.highlightAll())
  }, [children])

  return (
    <Section className={className}>
      <Container>
        <Content
          customColWidths
          className={classNames(
            "col-span-full grid-cols-1",
            "md:col-span-10 md:col-start-2",
            "xl:col-span-8 xl:col-start-3",
          )}
        >
          <article className="blog-body-code-mono-override prose prose-slate max-w-full lg:prose-lg prose-a:font-normal prose-code:text-base prose-code:before:hidden prose-code:after:hidden [&_a:any-link]:underline">
            {children}
          </article>
        </Content>
      </Container>
    </Section>
  )
}
