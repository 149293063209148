import classNames from "classnames"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Avatar } from "../freestanding/avatar/avatar"
import Container from "../freestanding/container"
import Overline from "../freestanding/overline/overline"
import Section from "../freestanding/section"
import {
  SocialLink,
  SupportedSocialMediaType,
} from "../freestanding/social-link/social-link"
import Wrapper from "../freestanding/wrapper"
import { BlogpostMetadata } from "./blog.model"

export type BlogHeroProps = {
  className?: string
  metadata: BlogpostMetadata
  socialLinks?: Array<{
    name: SupportedSocialMediaType
    link: string
  }>
}

export const BlogHero = ({
  className,
  metadata,
  socialLinks,
}: BlogHeroProps) => {
  const contentClasses = classNames(
    "col-span-full",
    "md:col-span-10 md:col-start-2",
    "xl:col-span-8 xl:col-start-3",
  )
  const image = getImage(metadata.featuredImage ?? null)
  const {
    category,
    linkToCategory,
    authors,
    publishedAtHumanReadable,
    publishedAtMachineReadable,
    title,
  } = metadata

  return (
    <Section className={className}>
      <Container>
        <Wrapper individualGap="gap-x-8">
          <div className={classNames("flex flex-col", contentClasses)}>
            <Link to={linkToCategory} className="pb-5">
              <Overline title={category} />
            </Link>
            <h1
              className={classNames(
                "pb-8 text-4xl font-semibold text-indigo-900",
                "md:text-5xl",
              )}
            >
              {title}
            </h1>
            <div className="flex flex-wrap gap-4 pb-6">
              {authors.map((author, index) => (
                <Avatar {...author} key={index} />
              ))}
            </div>
          </div>
          {image && (
            <GatsbyImage
              image={image}
              alt={title}
              className="col-span-full aspect-[1.91/1] w-full object-cover"
            />
          )}
          <div
            className={classNames(
              "flex items-center justify-between pt-4",
              contentClasses,
            )}
          >
            <time
              dateTime={publishedAtMachineReadable}
              className="text-gray-600"
            >
              {publishedAtHumanReadable}
            </time>
            <div className="flex content-end gap-2">
              {socialLinks?.map(({ name, link }) => (
                <SocialLink
                  key={name}
                  link={link}
                  type={name}
                  className="font-normal"
                />
              ))}
            </div>
          </div>
        </Wrapper>
      </Container>
    </Section>
  )
}
