import React from "react"
import { BlogCta, BlogCtaProps } from "./blog-cta"

const inputProps = {
  placeholder: "Your email address",
  type: "email",
  onSubmit: (email: string) => {
    window.open(
      "https://www.ory.sh/l/sign-up-newsletter?email=" +
        encodeURIComponent(email),
    )
  },
}

export const BlogSubscribeCta = (props: Partial<BlogCtaProps>) => (
  <BlogCta
    message={"Never miss an article - Subscribe to our newsletter!"}
    actionLabel={"Subscribe"}
    input={inputProps}
    {...props}
  />
)
