import classNames from "classnames"
import { Link } from "gatsby"
import { GatsbyImage, ImageDataLike, getImage } from "gatsby-plugin-image"

export type AvatarProps = {
  name: string
  position?: string
  imgSrc?: string | ImageDataLike
  link?: string
  className?: string
  /**
   * @default 'cover'
   */
  imageFit?: "contain" | "cover"
}

// based on hashing function from https://stackoverflow.com/a/40958826
function getHueAndSaturation(input: string) {
  let hash = 0
  const length = input.length
  for (let i = 0; i < length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i)
    hash |= 0 // to 32bit integer
  }

  return { hue: hash % 360, saturation: hash % 100 }
}

export const Avatar = ({
  name,
  position,
  imgSrc,
  link,
  className,
  imageFit = "cover",
}: AvatarProps) => {
  const { hue, saturation } = getHueAndSaturation(name)

  let profileImage = (
    <div
      className={classNames(
        "grid aspect-square w-12 place-content-center rounded-full text-2xl text-white",
      )}
      style={{
        backgroundColor: `hsl(${hue}deg ${saturation}% 30%)`,
      }}
    >
      {name[0]}
    </div>
  )

  if (imgSrc) {
    const authorImageData =
      imgSrc && typeof imgSrc !== "string" ? getImage(imgSrc) : undefined

    if (typeof imgSrc === "string") {
      profileImage = (
        <img
          src={imgSrc}
          alt={`Photo of ${name}`}
          className={classNames("aspect-square w-12 rounded-full", {
            "object-cover": imageFit === "cover",
            "object-contain": imageFit === "contain",
          })}
        />
      )
    } else if (authorImageData) {
      profileImage = (
        <GatsbyImage
          image={authorImageData}
          alt={`Photo of ${name}`}
          className={classNames("aspect-square w-12 rounded-full", {
            "object-cover": imageFit === "cover",
            "object-contain": imageFit === "contain",
          })}
        />
      )
    }
  }

  const content = (
    <>
      {profileImage}
      <div className="flex flex-col text-[13.2px]">
        <span className={classNames("font-medium")}>{name}</span>
        {position && <span className="text-gray-600">{position}</span>}
      </div>
    </>
  )
  const wrapperClasses = classNames(
    "flex items-center gap-2 w-64",
    link ? "text-cyan-500" : "text-indigo-900",
    className,
  )

  if (!link) {
    return <div className={wrapperClasses}>{content}</div>
  }

  const LinkComponent = link.includes("http") ? "a" : Link

  return link.includes("http") ? (
    <a className={wrapperClasses} href={link}>
      {content}
    </a>
  ) : (
    <LinkComponent className={wrapperClasses} to={link}>
      {content}
    </LinkComponent>
  )
}
