import classNames from "classnames"
import { X as CloseIcon } from "phosphor-react"
import React, {
  HTMLInputTypeAttribute,
  HTMLProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"

export type InputProps = {
  /**
   * @default "text"
   */
  type?: HTMLInputTypeAttribute
  label?: string
  placeholder: string
  caption?: string
  onChange?: (value: string) => void
  defaultValue?: string
  disabled?: boolean
  icon?: ReactNode
  className?: string
  inputProps?: Omit<
    HTMLProps<HTMLInputElement>,
    | "type"
    | "placeholder"
    | "label"
    | "onChange"
    | "disabled"
    | "className"
    | "value"
    | "ref"
  >
}

export const Input = ({
  type = "text",
  label,
  placeholder,
  caption,
  onChange,
  defaultValue,
  disabled,
  icon,
  className,
  inputProps = {},
}: InputProps) => {
  const [value, setValue] = useState(defaultValue ?? "")
  const inputRef = useRef<HTMLInputElement | null>(null)

  const inputOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    onChange?.(value)
  }, [value])

  const onClear: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setValue("")
    inputRef.current?.focus()
  }

  return (
    <label
      className={classNames(
        "flex cursor-pointer flex-col gap-1 text-[13.2px]",
        disabled ? "cursor-not-allowed text-gray-400" : "text-gray-600",
        className,
      )}
    >
      {label && (
        <span className={classNames(!disabled && "text-indigo-900")}>
          {label}
        </span>
      )}
      <span
        className={classNames(
          "flex items-center gap-1 border border-current p-3",
          !disabled &&
            "cursor-text focus-within:bg-white focus-within:text-cyan-400 active:bg-white active:text-cyan-400",
        )}
      >
        <span className="flex flex-grow items-center gap-1 xl:text-base">
          {icon}
          <input
            {...inputProps}
            className={classNames(
              "flex-1 bg-transparent leading-tight focus:outline-none active:outline-none xl:leading-normal",
              disabled
                ? "cursor-not-allowed placeholder-gray-400"
                : "text-indigo-900 placeholder-gray-600",
            )}
            type={type}
            placeholder={placeholder}
            onChange={inputOnChange}
            value={value}
            disabled={disabled}
            ref={inputRef}
          />
        </span>
        {!disabled && value && (
          <button onClick={onClear}>
            <CloseIcon alt="Clear input" className="text-gray-600" />
          </button>
        )}
      </span>
      {caption && <aside>{caption}</aside>}
    </label>
  )
}
