import classNames from "classnames"
import { Link } from "gatsby"
import {
  FacebookLogo,
  GithubLogo,
  LinkedinLogo,
  TwitterLogo,
  Icon as PhosphorIcon,
  SlackLogo,
  YoutubeLogo,
} from "phosphor-react"

const knownIcons = {
  github: GithubLogo,
  twitter: TwitterLogo,
  linkedin: LinkedinLogo,
  facebook: FacebookLogo,
  slack: SlackLogo,
  youTube: YoutubeLogo,
} as const

export type SupportedSocialMediaType = keyof typeof knownIcons

export type SocialLinkProps = {
  className?: string
  link: string
  type: SupportedSocialMediaType
}

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1)

export const SocialLink = ({ className, link, type }: SocialLinkProps) => {
  const Icon: PhosphorIcon = knownIcons[type]
  return (
    <Link
      to={link}
      aria-label={capitalize(type)}
      className={classNames(
        "m-0 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full p-0 text-indigo-900 hover:text-cyan-500 [&>svg]:h-8 [&>svg]:w-8",
        className,
      )}
    >
      <Icon weight="fill" />
    </Link>
  )
}
