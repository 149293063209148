import classNames from "classnames"
import { KeyboardEventHandler, PropsWithChildren, useState } from "react"
import { useButtonStyleClasses } from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import { Input, InputProps } from "../../freestanding/input/input"
import Section from "../../freestanding/section"
import Wrapper from "../../freestanding/wrapper"

export type BlogCtaProps = {
  message: string
  actionLabel: string
  className?: string
  input?: Omit<InputProps, "onChange" | "className"> & {
    onSubmit: (value: string) => void
  }
  fullWidth?: boolean
}

const ContentWrapper = ({
  children,
  className,
  skip,
}: PropsWithChildren<{
  className?: string
  skip?: boolean
}>) =>
  skip ? (
    <>{children}</>
  ) : (
    <Section noVerticalPadding className={className}>
      <Container>
        <Wrapper>{children}</Wrapper>
      </Container>
    </Section>
  )

export const BlogCta = ({
  message,
  className,
  actionLabel,
  input,
  fullWidth = false,
}: BlogCtaProps) => {
  const [value, setValue] = useState("")

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    input?.onSubmit?.(value)
  }

  const onKeyDown: KeyboardEventHandler = (event) => {
    if (event.code === "Enter") {
      input?.onSubmit?.(value)
    }
  }

  const buttonStyleClasses = useButtonStyleClasses({
    variant: "primary",
  })

  return (
    <ContentWrapper className={className} skip={fullWidth}>
      <Content
        customColWidths
        className={classNames(
          "gap-6 md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3",
          {
            "items-center lg:grid-cols-2": fullWidth,
          },
        )}
      >
        <em
          className={classNames(
            "text-3xl font-semibold not-italic text-indigo-900",
            "md:text-4xl",
          )}
        >
          {message}
        </em>
        <form
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          className="flex items-end gap-4"
        >
          {input ? (
            <Input {...input} onChange={setValue} className="flex-grow" />
          ) : null}
          <button className={buttonStyleClasses}>{actionLabel}</button>
        </form>
      </Content>
    </ContentWrapper>
  )
}
