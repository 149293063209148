import classNames from "classnames"
import { PropsWithChildren } from "react"

interface PropTypes {
  colWidthMd?: 6 | 12
  colWidthLg?: 6 | 8 | 12
  colWidthXL?: 6 | 8 | 12
  boxed?: boolean
  className?: string
  customColWidths?: boolean
}

const Content = ({
  colWidthXL = 8,
  colWidthLg = 8,
  colWidthMd = 12,
  children,
  className,
  boxed = false,
  customColWidths = false,
}: PropsWithChildren<PropTypes>) => {
  return (
    <div
      className={classNames(
        "col-span-full grid h-fit gap-y-8 md:gap-y-12 xl:gap-y-16",
        className,
        boxed && "lg:max-w-[535px]",
        !customColWidths && {
          "md:col-span-6": colWidthMd === 6,
          "md:col-span-12": colWidthMd === 12,
          "lg:col-span-6": colWidthLg === 6,
          "lg:col-span-8": colWidthLg === 8,
          "lg:col-span-12": colWidthLg === 12,
          "xl:col-span-6": colWidthXL === 6,
          "xl:col-span-8": colWidthXL === 8,
          "xl:col-span-12": colWidthXL === 12,
        },
      )}
    >
      {children}
    </div>
  )
}

export default Content
