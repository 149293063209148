import classNames from "classnames"
import { graphql, HeadProps, PageProps } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { ArrowLeft } from "tabler-icons-react"
import Layout from "../relaunch/components/layouts/layout/layout"
import { BlogHero } from "../relaunch/components/blog/blog-hero"
import { BlogBody } from "../relaunch/components/blog/body/blog-body"
import { BlogSubscribeCta } from "../relaunch/components/blog/cta/blog-subscribe-cta"
import Container from "../relaunch/components/freestanding/container"
import IconLink from "../relaunch/components/freestanding/icon-link/icon-link"
import Section from "../relaunch/components/freestanding/section"
import Wrapper from "../relaunch/components/freestanding/wrapper"
import { Blogpost, getBlogpost } from "../relaunch/components/blog/blog.model"

export interface PlainBlogTemplateProps {
  children: React.ReactNode
  blogpost: Blogpost
}

export function PlainBlogTemplate({
  children,
  blogpost,
}: PlainBlogTemplateProps) {
  return (
    <Layout>
      <BlogHero metadata={blogpost.metadata} />
      <BlogBody>{children}</BlogBody>
      <BlogSubscribeCta />
      <Section noVerticalPadding className="pt-6 md:pt-10 xl:pt-14">
        <Container>
          <Wrapper individualGap="gap-x-8">
            <div
              className={classNames(
                "col-span-full",
                "md:col-span-10 md:col-start-2",
                "xl:col-span-8 xl:col-start-3",
              )}
            >
              <IconLink
                ariaLabel="Back to blog"
                title="Back to blog"
                to="/blog"
                icon={ArrowLeft}
              />
            </div>
          </Wrapper>
        </Container>
      </Section>
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.singleBlogEntryQuery>) => {
  const {
    seo,
    metadata: { title, description },
  } = getBlogpost(data.mdx!)
  return (
    <>
      <title>{seo?.title || title}</title>
      <meta name="description" content={seo?.description || description} />
      <meta property="og:title" content={seo?.title || title} />

      <meta
        property="og:description"
        content={seo?.description || description}
      />

      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={seo?.title || title} />

      <meta
        property="twitter:description"
        content={seo?.description || description}
      />
    </>
  )
}

export default function BlogTemplate({
  data,
}: PageProps<Queries.singleBlogEntryQuery>) {
  if (!data.mdx) {
    throw new Error("Blogpost not found!¬")
  }
  const blogpost = getBlogpost(data.mdx)
  const body = blogpost.content.body

  return (
    <PlainBlogTemplate blogpost={blogpost}>
      {body && <MDXRenderer>{body}</MDXRenderer>}
    </PlainBlogTemplate>
  )
}

export const pageQuery = graphql`
  query singleBlogEntry($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      ...BlogpostFull
    }
  }
`
